import api from "./apiService";

export async function logout() {
  const { data, status } = await api.post("/logout/");
  return { response: data.response, error: status >= 300 };
}

export async function changeAvatar(avatarNumber) {
  const { data, status } = await api.post("/change_user_avatar/", {
    avatarNumber,
  });
  return { response: data.response, error: status >= 300 };
}

export async function changePassword(oldPassword, newPassword) {
  const { data, status } = await api.post("/change_password_known/", {
    oldPassword,
    newPassword,
  });
  return { response: data.response, error: status >= 300 };
}

export async function forgotPassword({ newPassword, email, captcha }) {
  const { data, status } = await api.post("/change_password_unknown/", {
    newPassword,
    email,
    captcha,
  });
  return { response: data.response, error: status >= 300 };
}

export async function userToGod(email) {
  const { data, status } = await api.post(
    "/api/account/transform/user_to_god/",
    { email }
  );
  return { response: data.response, error: status >= 300 };
}
