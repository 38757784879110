import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation, Trans } from "react-i18next";
import LinkTo from "../../components/main/linkTo";
import TeacherInfoCard from "../../components/main/teacherInfoCard";

const TeachersPage = () => {
  const { t } = useTranslation("static");
  const { t: tc } = useTranslation("common");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="teachers-intro">
      <Helmet>
        <title>Teachers - Speechtopia</title>
      </Helmet>
      <img
        alt="Speechtopia Debating Teachers"
        src="/static/vectors/speechtopia-astronaut-teacher.png"
        className="teachers-intro--astronaut"
      />
      <h1 className="teachers-intro--title">{tc("titles.teachers")}</h1>
      <div className="teachers-intro__teachers">
        <p className="teachers-intro--description">
          <Trans
            i18nKey="static:teachers.description"
            defaultValue="Our teaching staff is composed of professionally trained world-class
            Speech and Debate competitors. We teach the skills needed to compete
            at an international level as well as cultivate
            <strong> Self-Confidence</strong>,<strong> Self-Awareness </strong>
            and
            <strong> Self-Advocacy</strong>. We create global competitors and
            global citizens"
            components={{ bold: <strong style={{ color: "#00eaf2" }} /> }}
          />
        </p>
        <TeacherInfoCard
          data={{
            name: "Ms. J",
            image: "/static/images/speechtopia-julia-qin.jpeg",
            bio: t("teachers.julia"),
          }}
        />

        <TeacherInfoCard
          data={{
            name: "Ms. R",
            image: "/static/images/roop.jpg",
            bio: t("teachers.roop"),
          }}
        />
        <TeacherInfoCard
          data={{
            name: "Mr. N",
            image: "/static/images/nir.jpg",
            bio: t("teachers.nir"),
          }}
        />

        <TeacherInfoCard
          data={{
            name: "Mr. B",
            image: "/static/images/brandon.jpg",
            bio: t("teachers.brandon"),
          }}
        />
        <TeacherInfoCard
          data={{
            name: "Mr. M",
            image: "/static/images/mattias.png",
            bio: t("teachers.mattias"),
          }}
        />
        <TeacherInfoCard
          data={{
            name: "Mr. A",
            image: "/static/images/author.png",
            bio: t("teachers.author"),
          }}
        />
        <TeacherInfoCard
          data={{
            name: "Ms. A",
            image: "/static/images/amanda.png",
            bio: t("teachers.amanda"),
          }}
        />
        <TeacherInfoCard
          data={{
            name: "Mr. J",
            image: "/static/images/josh.png",
            bio: t("teachers.josh"),
          }}
        />
        <TeacherInfoCard
          data={{
            name: "Ms. T",
            image: "/static/images/taira.png",
            bio: t("teachers.taira"),
          }}
        />
      </div>
      <h2
        className="intro__links__box"
        style={{ textAlign: "center", width: "100%", padding: "1rem" }}
      >
        <span>
          <a href="/contact-us" style={{ color: "#00eaf2" }}>
            Contact us if you are interested in joining our team!
          </a>
        </span>
      </h2>
    </div>
  );
};

export default TeachersPage;
